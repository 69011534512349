<template>
  <div>
    <!--end::Theme mode setup on page load-->
    <!--begin::App-->
    <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
      <!--begin::Page-->
      <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
        <!-- HEADER -->
        <TheHeader /> <!-- END HEADER -->
        <!--begin::Wrapper-->
        <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <!--begin::Sidebar-->
          <TheSidebar />
          <!--end::Sidebar-->
          <!--begin::Main-->
          <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
            <!--begin::Content wrapper-->
            <div class="d-flex flex-column flex-column-fluid">
              <!--begin::Toolbar-->
              <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
                <!--begin::Toolbar container-->
                <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
                  <slot name="toolbar"></slot>
                </div>
                <!--end::Toolbar container-->
              </div>
              <!--end::Toolbar-->
              <div id="global-messages" v-if="errors">
                <BaseAlert
                  v-if="errors"
                  :content="errors"
                  type="error"
                  @dismiss-alert="clearGlobalErrors"
                />
              </div>

              <transition name="slide-fade">
                <QuickAlert
                  v-if="notification"
                  :type="notification.type"
                  :content="notification.content"
                />
              </transition>
              <!--begin::Content-->
              <div id="kt_app_content" class="app-content flex-column-fluid">
                <!--begin::Content container-->
                <div id="kt_app_content_container" class="app-container container-fluid">
                  <slot></slot>
                </div>
                <!--end::Content container-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Content wrapper-->
            <!--begin::Footer-->
            <div id="kt_app_footer" class="app-footer">
              <!--begin::Footer container-->
              <div class="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                <!--begin::Copyright-->
                <div class="text-dark order-2 order-md-1">
                  <span class="text-muted fw-semibold me-1">{{ currentYear }}&copy;</span>
                  Pixel Roads
                </div>
                <!--end::Copyright-->
                <!--begin::Menu-->
                <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                </ul>
                <!--end::Menu-->
              </div>
              <!--end::Footer container-->
            </div>
            <!--end::Footer-->
          </div>
          <!--end:::Main-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Page-->
    </div>
    <!--end::App-->
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import BaseAlert from '@/components/common/Alert/BaseAlert'
import QuickAlert from '@/components/common/Alert/QuickAlert'
import TheHeader from '@/components/headers/TheHeader'
import TheSidebar from '@/components/sidebars/TheSidebar'

export default {
  name: 'MainLayout',
  components: {
    BaseAlert,
    QuickAlert,
    TheHeader,
    TheSidebar
  },
  computed: {
    ...mapState({
      errors: state => state.errors,
      notification: state => state.notification
    }),
    currentYear () {
      return new Date().getFullYear()
    }
  },
  methods: {
    ...mapActions(['clearErrors']),
    clearGlobalErrors () {
      this.clearErrors()
    }
  }
}
</script>

<style lang="scss" scoped>
#global-messages {
  position: fixed;
  top: 120px;
  left: calc(50% - 140px + 132px);
  width: 280px;
  z-index: 10;

  @include tablet {
    width: 500px;
    left: calc(50% - 250px + 132px);
  }
}
</style>
